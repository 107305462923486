import React, { useState, useEffect, useCallback, useRef } from "react";
import { getVdrByUser } from "../../Queries/VdrApi/vdrApi";
import VdrHistoryItem from "./VdrHistoryItem";

const VdrHistory = () => {
    const [vehicleDamageReports, setVehicleDamageReports] = useState([]);
    const [searchTerms, setSearchTerms] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [recordsPerPage] = useState(20);
    const [totalRecords, setTotalRecords] = useState(0);

    // Ref to track loading state without causing re-renders
    const loadingRef = useRef(false);

    // Fetch data
    const fetchData = useCallback(async () => {
        if (loadingRef.current) return; // Prevent multiple simultaneous fetches

        loadingRef.current = true; // Set loading to true
        try {
            const data = await getVdrByUser(pageNumber, recordsPerPage, searchTerms);
            setVehicleDamageReports((prev) => [...prev, ...(data?.value ?? [])]); // Append new data
            setTotalRecords(data?.["@odata.count"] ?? 0);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            loadingRef.current = false; // Set loading to false
        }
    }, [pageNumber, recordsPerPage, searchTerms]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSearch = (e) => {
        setSearchTerms(e.target.value);
        setPageNumber(1);
        setVehicleDamageReports([]);
    };

    // Infinite scroll listener
    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + window.scrollY >=
            document.documentElement.offsetHeight - 200
        ) {
            if (!loadingRef.current && pageNumber * recordsPerPage < totalRecords) {
                setPageNumber((prev) => prev + 1);
            }
        }
    }, [pageNumber, recordsPerPage, totalRecords]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]); // Add handleScroll to the dependency array

    return (
        <>
            <div className="sticky-top bg-light">
                <div className="row">
                    <div className="col text-center mt-2 shadow w-100">
                        <h2>Vehilce Reports</h2>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col">
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                value={searchTerms}
                                onChange={handleSearch}
                                className="form-control"
                                placeholder="Search by Year, Make, Model, Trim, or VIN"
                                aria-label="Search"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                {vehicleDamageReports.length > 0 ? (
                                    vehicleDamageReports.map((vdr, index) => (
                                        <VdrHistoryItem
                                            key={vdr.VehicleDamageReportId || index}
                                            index={index}
                                            vdr={vdr}
                                        />
                                    ))
                                ) : !loadingRef.current ? (
                                    <div>No records found.</div>
                                ) : null}
                                {loadingRef.current && <div>Loading...</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VdrHistory;
