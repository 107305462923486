import React from 'react';
import PropTypes from 'prop-types';
import DamageDetailItem from './DamageDetailItem';

const DamageDetailsList = ({ damageDetails }) => {
    return (
        <div className='mt-3'>
            <h4>Damage Information</h4>
            {damageDetails.sort(
                (a, b) => new Date(b.DateOfLoss) - new Date(a.DateOfLoss)
            ).map((damageRecord, index) => (
                <DamageDetailItem key={index} damageRecord={damageRecord} />
            ))}
        </div>
    );
};

DamageDetailsList.propTypes = {
    damageDetails: PropTypes.arrayOf(
        PropTypes.shape({
            DateOfLoss: PropTypes.string, // Use `string` for ISO date strings
            GrossTotalAmount: PropTypes.number,
            StateProv: PropTypes.string,
            Country: PropTypes.string,
            PrimaryPointOfImpact: PropTypes.string,
            SecondaryPointOfImpact: PropTypes.string,
            LossDescription: PropTypes.string,
        })
    ).isRequired,
};

export default DamageDetailsList;
