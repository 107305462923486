import React from "react";
import PropTypes from "prop-types";

const DamageDetailItem = ({ damageRecord }) => {
  const formattedDate = damageRecord.DateOfLoss
    ? new Date(damageRecord.DateOfLoss).toLocaleString([], {
        dateStyle: "short",
      })
    : "";
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "CAD",
  }).format(damageRecord.GrossTotalAmount);

  return (
    <>
      <div
        className="card shadow mt-3 border-0"
        style={{ "--bs-bg-opacity": ".5", backgroundColor: "#e5e7eb" }}
      >
        <div className="card-body" style={{ "color:": "#8b8b8b" }}>
          <div>
            <span
              className="material-symbols-outlined text-danger"
              style={{ fontSize: "1.5rem" }}
            >
              car_crash
            </span>
          </div>
          <div>Date: {formattedDate}</div>
          <div>
            Location: {damageRecord.StateProv}&nbsp;{damageRecord.Country}
          </div>
          <div>Amount: {damageRecord.formattedAmount}</div>
          <div>
            Point of Imapact: {damageRecord.PrimaryPointOfImpact}&nbsp;
            {damageRecord.SecondaryPointOfImpact}
          </div>
          <div>Claim Type: {damageRecord.LossDescription}</div>
        </div>
      </div>
    </>
  );
};

DamageDetailItem.propTypes = {
  damageRecord: PropTypes.shape({
    DateOfLoss: PropTypes.string, // Use `string` for ISO date strings
    GrossTotalAmount: PropTypes.number,
    StateProv: PropTypes.string,
    Country: PropTypes.string,
    PrimaryPointOfImpact: PropTypes.string,
    SecondaryPointOfImpact: PropTypes.string,
    LossDescription: PropTypes.string,
  }).isRequired,
};

export default DamageDetailItem;
