import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

function NavBar() {
    const location = useLocation();
    const navigate = useNavigate();

    // Define the navigation items
    const navItems = [
        { path: "/", label: "Home", icon: "house-door" },
        { path: "/vdrorder", label: "Order", icon: "car-front" },
        { path: "/vdrhistory", label: "Reports", icon: "archive" },
        { path: "/profile", label: "Profile", icon: "person" },
    ];

    return (
        <>
            {/* Desktop Navbar */}
            <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top d-none d-lg-flex">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">
                        {location.pathname === "/" ? "Home" : "Other Page"}
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {navItems.map((item) => (
                                <li className="nav-item">
                                    <a className="nav-link" href={item.path}>
                                        <span>
                                            {item.label}
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Mobile Bottom Navbar */}
            <nav className="navbar mobile-nav-bar navbar-light bg-light fixed-bottom d-lg-none">
                <div className="container d-flex justify-content-around">
                    {navItems.map((item) => (
                        <button
                            key={item.path}
                            className={`btn btn-link flex-column align-items-center ${location.pathname === item.path ? "text-primary" : "text-secondary"
                                }`}
                            onClick={() => navigate(item.path)}
                        >
                            <i class={`bi-${item.icon}`} style={{ fontSize: "1.5rem" }} />
                        </button>
                    ))}
                </div>
            </nav>
        </>
    );
}

export default NavBar;
