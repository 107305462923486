import React from "react";
import PropTypes from "prop-types";

const VehicleInfo = ({ vehicleInfo }) => {
    return (
        <>
            <h4>Vehicle Information</h4>
            <div className="card shadow mt-3 border-0" style={{"--bs-bg-opacity": ".5", "backgroundColor" : "#e5e7eb" }}>
                <div className="card-body" style={{ "color:":"#8b8b8b"} }>
                    <div>
                        <div>Year: {vehicleInfo.Year}</div>
                        <div>Make: {vehicleInfo.Make}</div>
                        <div>Model: {vehicleInfo.Model}</div>
                        <div>Trim/Style: {vehicleInfo.Trim}</div>
                        <div>Vin: {vehicleInfo.Vin}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

// PropTypes validation
VehicleInfo.propTypes = {
    vehicleInfo: PropTypes.shape({
        Year: PropTypes.number.isRequired,
        Make: PropTypes.string.isRequired,
        Model: PropTypes.string.isRequired,
        Trim: PropTypes.string.isRequired,
        Vin: PropTypes.string.isRequired,
    }).isRequired,
};

export default VehicleInfo;
