import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getVdrById } from "../../Queries/VdrApi/vdrApi";
import VehicleInfo from "./VehicleInfo";
import DamageDetailsList from "./DamageDetailsList";
import RecallDetails from "./RecallDetails";

const Vdr = () => {
  const { id } = useParams();
  const [vdr, setVdr] = useState(null);
  const [loading, setLoading] = useState(false);

  // UseEffect to fetch data when component mounts or id changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getVdrById(id);
      setVdr(data.value[0]);
      setLoading(false);
    };

    fetchData();
  }, [id]); // Adding id to the dependency array

  return (
    <>
      <div className="sticky-top bg-light">
        <div className="row">
          <div className="col text-center mt-2 shadow w-100">
            <h2>Vehicle Damage Report</h2>
          </div>
        </div>
      </div>
      <div>
        {loading ? (
          <p>Loading...</p>
        ) : vdr ? (
          <>
            <div className="mt-3 mb-3">
              <VehicleInfo
                vehicleInfo={{
                  Year: vdr.VinDecodeInfo.Year,
                  Make: vdr.VinDecodeInfo.Make,
                  Model: vdr.VinDecodeInfo.Model,
                  Trim: vdr.VinDecodeInfo.Trim,
                  Vin: vdr.Vin,
                }}
              />
              <DamageDetailsList damageDetails={vdr.DamageDetails} />
              <RecallDetails vdr={vdr} />
            </div>
          </>
        ) : (
          <p>No vehicle damage report found.</p>
        )}
      </div>
    </>
  );
};
export default Vdr;
