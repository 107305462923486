import React from "react";
import PropTypes from "prop-types";

const RecallItem = ({ recallRecord }) => {
  return (
    <div
      className="card shadow mt-3 border-0"
      style={{ "--bs-bg-opacity": ".5", backgroundColor: "#e5e7eb" }}
    >
      <div className="card-body" style={{ "color:": "#8b8b8b" }}>
        <div
          className={`${recallRecord.iconClass} material-symbols-outlined`}
          style={{ fontSize: "1.5rem" }}
        >
          handyman
        </div>
        <div>{recallRecord.Title}</div>
      </div>
    </div>
  );
};

RecallItem.propTypes = {
  recallRecord: PropTypes.shape({
    key: PropTypes.string, // Unique identifier
    Title: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecallItem;
