import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";


const VdrHistoryItem = ({ vdr }) => {
    const navigate = useNavigate();
    const viewVdr = (vehicleDamageReportId) => {
        navigate(`/Vdr/${vehicleDamageReportId}`);
    };

    const checkHasDamage = () => {
        return vdr.DamageDetails.length > 0;
    }
    const checkRecall = () => {
        return (vdr.RecallDetails.length > 0);
    }
    const checkRecallCompelte = () => {
        return vdr.VdrSources.RecallStatus;
    }

    const hasDamage = checkHasDamage();
    const hasRecall = checkRecall();
    const recallComplete = checkRecallCompelte();

    const recallBadgeClass = vdr.VdrSources.RecallCheckId != null && vdr.VdrSources.RecallStatus
        ? vdr.RecallDetails.length > 0 ? "bg-danger" : ""
        : "text-warning";

    return (
        <div key={vdr.VehicleDamageReportId} onClick={() => viewVdr(vdr.VehicleDamageReportId)}>
            <div className="card mt-1 shadow mt-3 pt-2 pb-2 bg-secondary border-0" style={{"--bs-bg-opacity": ".5"} }>
                <div className="row">
                    {/* Vehicle Details */}
                    <div className="col-8 ms-2">
                        <div className="fw-bold">{`${vdr.VinDecodeInfo.Year} ${vdr.VinDecodeInfo.Make} ${vdr.VinDecodeInfo.Model} ${vdr.VinDecodeInfo.Trim}`}</div>
                        <div className="row">
                            <div className="col">
                                <div className="text-muted">VIN: {vdr.Vin}</div>
                            </div>
                        </div>
                    </div>
                    {/* Report Details */}
                    <div className="col-3 me-2 d-flex align-items-center justify-content-center">
                        {/* clean */}
                        {(!hasDamage && recallComplete && !hasRecall )?
                            <span className="material-symbols-outlined text-primary" style={{ fontSize: "2rem" }}>search_check</span>
                            :
                            <span className="material-symbols-outlined text-danger" style={{ fontSize: "1.5rem" }}>warning</span>
                        }
                        {/* damage */}
                        {hasDamage ?
                            <span className="material-symbols-outlined text-danger" style={{ fontSize: "1.5rem" }}>car_crash</span>
                            :
                            <></>
                        }
                        {/* recall */}
                        {!recallComplete ?
                            <span
                                className="spinner-border spinner-border-sm"
                                style={{ fontSize: "1.5rem" }}
                                role="status"
                                aria-hidden="true"
                            ></span> :
                            hasRecall ?
                                <span className="material-symbols-outlined text-danger" style={{ fontSize: "1.5rem" }}>handyman</span>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

VdrHistoryItem.propTypes = {
    vdr: PropTypes.shape({
        VehicleDamageReportId: PropTypes.number.isRequired,
        DamageDetails: PropTypes.array.isRequired,
        VdrSources: PropTypes.object.isRequired,
        RecallDetails: PropTypes.array.isRequired,
        VinDecodeInfo: PropTypes.shape({
            Year: PropTypes.number.isRequired,
            Make: PropTypes.string.isRequired,
            Model: PropTypes.string.isRequired,
            Trim: PropTypes.string,
        }).isRequired,
        Vin: PropTypes.string.isRequired,
        UserName: PropTypes.string,
        LookupDate: PropTypes.string,
    }).isRequired
};

export default VdrHistoryItem;
