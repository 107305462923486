import React from "react";
import PropTypes from "prop-types";
import RecallItem from "./RecallItem";

const RecallDetails = ({ vdr }) => {
  // Determine the header class based on VDR source and recall status
  const hasRecall = vdr.RecallDetails.length > 0;
  const noRecallAvailable = vdr.RecallDetails.length > 0;

  return (
    <>
      <h4 className="mt-3">
        Recall Information
      </h4>
      <div>
        {vdr.VdrSources.RecallCheckId == null ||
        !vdr.VdrSources.RecallStatus ? (
          <RecallItem
            recallRecord={{
              key: "no-recalls-available",
              Title: "No Recalls Available for this OEM",
              iconClass: "text-warning"
            }}
          />
        ) : vdr.RecallDetails.length === 0 ? (
          <RecallItem
            recallRecord={{
              key: "no-recalls-found",
              Title: "No Recalls Found",
              iconClass: "text-success"
            }}
          />
        ) : (
          vdr.RecallDetails.map((detail, index) => (
            <RecallItem
              key={`recall-${index}`}
              recallRecord={{
                key: `recall-${index}`,
                Title: detail.Title,
                iconClass: "text-danger"
              }}
            />
          ))
        )}
      </div>
    </>
  );
};

RecallDetails.propTypes = {
  vdr: PropTypes.shape({
    VdrSources: PropTypes.shape({
      RecallCheckId: PropTypes.number,
      RecallStatus: PropTypes.bool.isRequired,
    }).isRequired,
    RecallDetails: PropTypes.arrayOf(
      PropTypes.shape({
        Title: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default RecallDetails;
